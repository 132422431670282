<template>
  <!-- 产品线-弹框内容开始 -->
  <div v-if="visible">
    <el-dialog v-ismove title="配置" :visible="true" :close-on-click-modal="false" :before-close="closeTab">
      <el-form label-width="100px" class="demo-ruleForm">
        <el-form-item label="产品线">
          <el-checkbox v-model="allChecked" @change="changeAll">全选</el-checkbox>
          <el-checkbox-group v-model="checklist" @change="changeSelect">
            <el-checkbox v-for="(pro,index) in productLineList" :label="pro.plId" :key="index" :disabled="pro.isDisabled">
              {{pro.plName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeTab">取消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
  <!-- 产品线-弹框内容结束 -->
</template>

<script>
import { updateProductLine } from '@/api/role'

export default {
  data() {
    return {
      allChecked: false,
      visible: false,
      productLineList: [],
      checklist: []
    }
  },
  props: {
    productlineObj: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  watch: {
    'productlineObj.visible': function(val) {
      this.visible = val
      if (val) {
        this.allChecked = false
        this.checklist = this.productlineObj.checkedList
        this.productLineList = this.productlineObj.productLineList
      }
    }
  },
  methods: {
    submit() {
      // const ids = []
      // for (const j of this.checklist) {
      //   this.productLineList.filter(i => {
      //     if (i.plId === j && i.disabled === undefined) {
      //       ids.push(j)
      //     }
      //   })
      // }
      const disArr = this.productLineList.filter(item => {
        return item.isDisabled
      })
      const pramas = {
        productLineIds: this.checklist.filter(item => { return disArr.indexOf(item) === -1 }),
        userId: this.productlineObj.userId
      }
      updateProductLine(pramas).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.$emit('close')
        } else {
          this.$message.error(res.message)
        }
      }).catch()
    },
    closeTab() {
      this.$emit('close')
    },
    changeAll(val) {
      if (val) {
        this.checklist = this.productLineList.map(item => {
          return item.plId
        })
      } else {
        this.checklist = this.productLineList.map(item => {
          return item.isDisabled ? item.plId : null
        })
      }
    },
    changeSelect(val) {
      this.allChecked = val.length === this.productLineList.length
    }
  }
}
</script>
