<template>
  <div class="app-container">
    <el-form ref="searchForm" :model="searchForm" inline size="small">
      <el-form-item label="部门" prop="department">
        <el-input v-model="searchForm.department" clearable placeholder="部门名称"></el-input>
      </el-form-item>
      <el-form-item label="岗位" prop="position">
        <el-input v-model="searchForm.position" clearable placeholder="岗位名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="公司" prop="ucId">
        <el-cascader
          v-model="searchForm.ucId"
          :options="companyData"
          :props="companyProps"
          change-on-select
          clearable
          :key="num"
          @change="getChildCompany"
          :show-all-levels="false">
        </el-cascader>
      </el-form-item>
      <el-form-item label="部门" prop="udId">
        <el-cascader
          v-model="searchForm.udId"
          :options="departTree"
          :props="departProps"
          change-on-select
          @change="getChildDepart"
          :show-all-levels="false"
          clearable
          :key="num"
          placeholder="请先选择公司">
        </el-cascader>
      </el-form-item>
      <el-form-item label="职位" prop="upId">
        <el-cascader
          v-model="searchForm.upId"
          :options="positionData"
          :props="positionProps"
          change-on-select
          @change="getPosition"
          :show-all-levels="false"
          clearable
          placeholder="请先选择部门">
        </el-cascader>
      </el-form-item> -->
      <el-form-item label="姓名" prop="name">
        <el-input v-model="searchForm.name" clearable placeholder="要查询的姓名"></el-input>
      </el-form-item>
      <el-form-item label="账号" prop="username">
        <el-input v-model="searchForm.username" clearable placeholder="登录账号"></el-input>
      </el-form-item>
      <el-form-item label="身份" prop="roleId">
        <el-select v-model="searchForm.roleId" clearable placeholder="账号角色类型">
          <el-option label="超级管理员" :value="1"></el-option>
          <el-option label="公司管理员" :value="2"></el-option>
          <el-option label="普通用户" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-select v-model="searchForm.state" clearable placeholder="账号状态">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号类型" prop="userType">
        <el-select v-model="searchForm.userType" clearable placeholder="账号类型">
          <el-option label="员工" :value="0"></el-option>
          <el-option label="非员工" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="clear">清空</el-button>
        <!-- 超管有新增角色 v-if="roleID===1" -->
        <!-- <el-button type="success" size="small" @click="addVisible=true">新增</el-button> -->
      </el-form-item>
    </el-form>
    <el-container>
      <el-aside width="220px" v-bind:style="{maxHeight: maxHeight + 'px'}" style="margin-top:10px">
        <el-tabs type="border-card">
          <el-tab-pane>
            <span slot="label"><i class="el-icon-s-platform"></i>部门架构</span>
            <!-- 部门级联数据 -->
            <el-tree
              ref="refdepartTree"
              :data="departTree"
              default-expand-all
              :expand-on-click-node="true"
              node-key="udId"
              @node-click="searchDept"
              :highlight-current="true"
              :props="deptProps">
            </el-tree>
          </el-tab-pane>
          <el-tab-pane>
            <span slot="label"><i class="el-icon-user-solid"></i>角色架构</span>
            <!-- 角色级联数据 -->
            <el-tree
              :data="roleTree"
              default-expand-all
              :expand-on-click-node="true"
              node-key="roleId"
              ref="tree"
              @node-click="searchRole"
              :highlight-current="true"
              :props="defaultProps">
            </el-tree>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-main v-bind:style="{maxHeight: maxHeight + 'px'}" style="padding: 10px">
        <el-table v-loading="loading" :data="dataList" border size="small" height="calc(100vh - 290px)" :header-cell-style="{background: '#41A1FF', color:'#fff'}">
          <el-table-column label="登录名" prop="username" width="120" align="center"></el-table-column>
          <el-table-column label="姓名" prop="name" width="120" align="center"></el-table-column>
          <el-table-column label="部门" prop="udName" width="150" align="center"></el-table-column>
          <el-table-column label="职位" prop="upName" width="150" align="center"></el-table-column>
          <el-table-column label="在职状态" width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status===1">在职</span>
              <span v-else-if="scope.row.status===0">离职</span>
            </template>
          </el-table-column>
          <el-table-column label="用户类型" width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.userType===0">员工</span>
              <span v-else-if="scope.row.userType===1">非员工</span>
            </template>
          </el-table-column>
          <el-table-column label="身份" width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.roleId === 1">超级管理员</span>
              <span v-else-if="scope.row.roleId === 2">公司管理员</span>
              <span v-else-if="scope.row.roleId === 3">普通用户</span>
            </template>
          </el-table-column>
          <el-table-column label="系统和权限" width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="系统权限" placement="top">
                <svg-icon v-if="scope.row.roleId===3" icon-class="setup"
                  style="width: 16px; height: 16px; cursor:pointer;"
                  @click="powerConf(scope.row)"></svg-icon>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column label="系统和权限（旧）" width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="系统权限" placement="top">
                <svg-icon v-if="scope.row.roleId===3" icon-class="setup"
                  style="width: 16px; height: 16px; cursor:pointer;"
                  @click="powerConf(scope.row)"></svg-icon>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <el-table-column label="启用/禁用" width="100" align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" :content="scope.row.state===1?'启用':'禁用'" placement="top">
                <el-switch v-model="scope.row.state"
                  @change="changeStatus($event, scope.row, scope.$index)"
                  :active-value="1"
                  :inactive-value="2"
                  :disabled="roleID != 1"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="账号生效截止时间" width="100" align="center" prop="effectTime">
          </el-table-column>
          <el-table-column label="拥有角色" prop="roleList">
            <template slot-scope="scope">
              <span>{{ (scope.row.systemRoleUserIdVoList ? scope.row.systemRoleUserIdVoList.map(item => item.roleName) : []).join(',') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分配产品线" width="100" align="center">
            <template slot-scope="scope">
              <el-button v-if="hasAction || roleID !== 3" @click="openProDialog(scope.row)" type="text">
                <i class="el-icon-tickets"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="编辑" placement="top">
                <i class="table_icons el-icon-edit" @click="editAccount(scope.row)"></i>
              </el-tooltip>
              <!-- 单独分配角色的接口未提供 暂时隐藏 -->
              <!-- <el-tooltip effect="dark" content="分配身份权限" placement="top">
                <svg-icon icon-class="empower" style="cursor: pointer;margin-right:10px" @click="roleConf(scope.row)"></svg-icon>
              </el-tooltip> -->
              <el-tooltip effect="dark" content="授予系统" placement="top">
                <svg-icon icon-class="allot" style="width: 16px; height: 16px;cursor: pointer" @click="systemConfOpen(scope.row)"></svg-icon>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:1em; text-align:center">
          <el-pagination
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="page"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
    <!-- 编辑账号 -->
    <edit-panel :editOptions="editOptions" :systemList="systemList" :productLineRoleList="productLineRoleList"
      :roleList="roleList" :roleTree="roleTree" @success="search" @close="closeEdit">
    </edit-panel>
    <!-- 新增账号 -->
    <add-panel :visible="addVisible" :roleTree="roleTree" :systemList="systemList" :roleList="roleList"
      :departTree="departTree" :productLineRoleList="productLineRoleList"
      :deptProps="deptProps" :positionProps="positionProps"
      @success="search" @close="closeAdd">
    </add-panel>
    <!-- 分配身份 -->
    <el-dialog v-ismove :visible.sync="identify" title="分配身份权限" width="600px" :close-on-click-modal="false" :before-close="closeTab">
      <el-input
        size="small"
        placeholder="输入关键字进行过滤"
        style="margin-bottom: 8px;"
        clearable
        v-model="filterText">
      </el-input>
      <el-tree
        ref="tree"
        :data="roleTree"
        :props="defaultProps"
        default-expand-all
        :check-on-click-node="true"
        node-key="roleId"
        :default-checked-keys="checkedIndetify"
        :filter-node-method="filterNode"
        icon-class="el-icon-d-arrow-right"
        show-checkbox>
      </el-tree>
      <div slot="footer">
        <el-button size="small" @click="closeTab">关闭</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 账号单独配置系统 -->
    <!-- <power-panel v-bind.sync="powerObj" @success="search"></power-panel> -->
    <power-conf v-bind.sync="powerObj"></power-conf>
    <!-- 按模块分权限不适配新的数据结构 -->
    <!-- <power-conf-old v-bind.sync="powerObjOld"></power-conf-old> -->
    <!-- 分配产品线 -->
    <productline-panel :productlineObj="productlineObj" @close="closeProduct"></productline-panel>
    <!-- 授予系统 -->
    <system-conf v-bind.sync="sysConf"></system-conf>

    <!-- 离职账号启用 -->
    <el-dialog title="离职账号启用提示" v-ismove
        :visible.sync="leaveVisible"
        width="600px"
        :close-on-click-modal="false"
        :before-close="closeLeaveDialog">
        <el-form ref="leaveForm" :model="leaveForm" :rules="leaveFormRule" inline size="small" label-width="140px">
          <el-form-item label="开启生效截止时间" prop="effectTime">
            <el-date-picker
              v-model="leaveForm.effectTime"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="需启用的系统" prop="usIds">
            <el-select v-model="leaveForm.usIds" clearable multiple filterable style="width: 220px;">
              <el-option v-for="item in leaveSystemList" :key="item.usId" :value="item.usId" :label="item.usName"></el-option>
              <template slot="empty">
                <p style="color:#909399;font-size:14px;text-align:center">请先给该账号分配系统</p>
              </template>
            </el-select>
          </el-form-item> -->
        </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeLeaveDialog">关闭</el-button>
        <el-button type="primary" size="small" @click="submitState">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 账号的自定义系统及权限，与绑定身份所带的系统与权限取并集
import { getuserPosition, getAllAccount, allSystem, userSystem, userRoleRightMap, resetPassword, getAuthorizationSystem, updateUserState } from '@/api/account'
import { getDepartTree, getRole, getCategory } from '@/api/role'
import { getRoleSupInfo } from '@/api/systemRole'
import { deepClone, cleanParams, roleChosedReset } from '@/utils/index'
import editPanel from './editPanel'
import addPanel from './addPanel'
// import powerPanel from '../role/empower'
import productlinePanel from './productLineConf'
import systemConf from './systemConf'
import powerConf from '@/components/powerConf'
// import powerConfOld from './editPowerOld.vue'
import * as validFn from '@/utils/validate'

export default {
  components: { editPanel, addPanel, productlinePanel, systemConf, powerConf },
  data() {
    return {
      searchForm: {
        // ucId: [],
        udId: [],
        upId: [],
        // department: '',
        // position: '',
        name: '',
        username: '',
        roleId: null,
        state: null,
        userType: null,
        systemRoleId: null
      },
      // departProps: {
      //   label: 'udName',
      //   value: 'udId',
      //   children: 'childrenDepartmentList'
      // },
      num: 1, // 级联数据清空，需要重新加载组件
      // companyData: [],
      // departData: [],
      positionData: [],
      positionProps: {
        label: 'upName',
        value: 'upId',
        children: 'childrenPositionList'
      },
      dataList: [],
      defaultProps: {
        label: 'roleName',
        value: 'roleId',
        children: 'supRoleList'
      },
      departTree: [],
      deptProps: {
        label: 'udName',
        children: 'children'
      },
      maxHeight: document.documentElement.clientHeight - 200,
      editOptions: {
        visible: false,
        userId: null,
        username: '',
        name: '',
        nameEn: '',
        telephone: '',
        mobilePhone: '',
        email: '',
        roleId: null,
        ucId: null,
        positionId: null,
        departmentId: null,
        roleIds: [],
        usIds: [],
        roleList: [],
        ucName: '',
        udName: '',
        upName: '',
        description: '',
        isOutside: null,
        systemRoleUserIdVoList: []
      },
      productlineObj: {
        userId: null,
        visible: false,
        productLineList: [],
        checkedList: []
      },
      systemList: [], // 系统列表
      roleList: [], // 身份列表
      addVisible: false,
      identify: false, // 分配身份
      checkedIndetify: [],
      filterText: '',
      powerObj: { // 系统和权限
        visible: false,
        userId: null,
        systemList: []
      },
      // powerObjOld: {
      //   visible: false,
      //   userId: null,
      //   systemList: []
      // },
      productLineRoleList: [], // 产品线角色
      roleTree: [], // 系统角色架构
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      depart_id: null, // 点击树形ID，直接触发请求？
      role_id: null, // 点击树形ID
      sysConf: {
        visible: false,
        systemList: [],
        ids: [],
        Id: null
      },
      leaveVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      leaveForm: {
        effectTime: '',
        state: null,
        // usIds: [],
        userId: null
      },
      leaveSystemList: [],
      leaveFormRule: {
        effectTime: [validFn.required()]
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  computed: {
    roleID() { return this.$store.getters.roles.userInfo.roleId }, // 当前登录账号roleId
    hasAction() { // 是否有分配产品线的action
      // let find = false
      // const urlList = this.$store.getters.actionUrl
      // if (urlList && urlList.length > 0) {
      //   urlList.forEach(item => {
      //     if (item === '/user/update/productLine') {
      //       find = true
      //     }
      //   })
      // }
      const find = (JSON.stringify(this.$store.getters.actionUrl)).includes('/user/update/productLine')
      return find
    }
  },
  mounted() {
    // this.getCompany()
    // 重新计算高度
    const _this = this
    window.onresize = () => {
      return (() => {
        window.maxHeight = document.documentElement.clientHeight - 200
        _this.maxHeight = window.maxHeight < 200 ? 200 : window.maxHeight
      })()
    }
    this.getDepart()
    this.getData()
    this.getAllSystems()
    this.getAllProductRole()
    this.getRoleSupList()
  },
  methods: {
    // 获取角色架构
    getRoleSupList() {
      getRoleSupInfo().then(res => {
        if (res.code === '000000') {
          this.roleTree = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取部门tree
    getDepart() {
      this.departTree = []
      getDepartTree().then(res => {
        if (res.code === '000000') {
          this.departTree = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 登录用户被指派的系统(超管则是全部系统)
    getAllSystems() {
      allSystem().then(res => {
        if (res.code === '000000') {
          this.systemList = res.data.list
        } else {
          this.systemList = []
        }
      }).catch(err => {
        console.log(err)
        this.systemList = []
      })
    },
    // 获取所有产品线角色
    getAllProductRole() {
      this.productLineRoleList = []
      getRole({ pageNum: 1, pageSize: 9999 }).then(res => {
        if (res.code === '000000' && res.data && res.data.list) {
          this.productLineRoleList = res.data.list
        }
      }).catch()
    },
    systemConfOpen(item) {
      // 根据userId 查询被授权的系统
      if (item.roleId === 1) {
        this.$message.warning('超管账号默认拥有全部系统权限')
      } else {
        getAuthorizationSystem(item.userId).then(res => {
          if (res.code === '000000') {
            this.sysConf = {
              visible: true,
              systemList: this.systemList,
              ids: res.data.map(item => item.usId),
              Id: item.userId
            }
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    // // 部门级联，每次用部门ID去请求子部门ID列表
    // getChildDepart() {
    //   this.positionData = []
    //   this.searchForm.upId = []
    //   const ID = this.searchForm.udId[this.searchForm.udId.length - 1]
    //   // 获取职位列表
    //   getuserPosition(ID).then(res => {
    //     if (res.code === '000000') {
    //       res.data.forEach(item => {
    //         if (item.hasMore) {
    //           item.childrenPositionList = []
    //         }
    //       })
    //       this.positionData = res.data
    //     }
    //   }).catch(() => {
    //     this.positionData = []
    //   })
    //   this.$forceUpdate()
    // },
    // 职位级联，每次用职位ID去请求下级职位列表
    getPosition() {
      const ID = this.searchForm.upId[this.searchForm.upId.length - 1]
      const udID = this.searchForm.udId[this.searchForm.udId.length - 1]
      getuserPosition(udID, ID).then(res => {
        if (res.code === '000000') {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenPositionList = []
            }
          })
          const parent = deepClone(this.positionData)
          this.findParent(ID, parent, res.data, 'childrenPositionList', 'upId')
          setTimeout(() => {
            this.positionData = parent
          }, 100)
        }
      }).catch(() => {
        this.positionData = []
      })
      this.$forceUpdate()
    },
    // 递归查找子公司列表该插到那个id下(部门，职位公用)
    findParent(id, arr, child, type, idType) {
      arr.forEach(item => {
        if (id === item[idType]) {
          if (child.length > 0) {
            item[type] = child
          }
        } else {
          if (item[type]) {
            this.findParent(id, item[type], child, type, idType)
          }
        }
      })
    },
    // 根据userId 查拥有的角色
    getRoles(userId) {
      return new Promise((resolve, reject) => {
        userRoleRightMap(userId).then(res => {
          let data = []
          if (res.code === '000000' && res.data && res.data.roleIds) {
            console.log('res', res)
            data = res.data.roleIds
          }
          resolve(data)
        }).catch(() => {
          reject()
        })
      })
    },
    // 根据userId 查拥有的系统
    getSystems(userId) {
      return new Promise((resolve, reject) => {
        userSystem(userId).then(res => {
          let data = []
          if (res.code === '000000' && res.data && res.data.usIds) {
            console.log('res', res)
            data = res.data.usIds
          }
          resolve(data)
        }).catch(() => {
          reject()
        })
      })
    },
    // 通过选择产品线角色得到的产品线配置不可修改，selectRoleProductLineIdList
    openProDialog(item) {
      getCategory(item.userId).then(res => {
        if (res.code === '000000') {
          const allLine = res.data.productLineVos
          const roleLine = res.data.selectRoleProductLineIdList
          const checkLine = res.data.selectProductLineIdList
          this.productlineObj.productLineList = allLine.map(item => {
            item.isDisabled = roleLine.indexOf(item.plId) > -1
            return item
          })
          this.productlineObj.checkedList = [...new Set(roleLine.concat(checkLine))]
          this.productlineObj.userId = item.userId
          this.productlineObj.visible = true
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    clear() {
      this.$refs.searchForm.resetFields()
      this.$nextTick(() => {
        this.$refs.refdepartTree.setCheckedKeys([])
        this.$refs.refdepartTree.setCurrentKey(null)
      })
      this.depart_id = null
    },
    search() {
      if (this.page === 1) {
        this.getData()
      } else {
        this.handleCurrentChange(1)
      }
    },
    getData() {
      this.searchForm.udId = this.depart_id
      const params = cleanParams(this.searchForm)
      params.pageNum = this.page
      params.pageSize = this.pageSize
      this.loading = true
      getAllAccount(params).then(res => {
        if (res.code === '000000' && res.data) {
          this.total = res.data.total
          this.dataList = res.data.list
        } else {
          this.total = 0
          this.dataList = []
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    },
    // 点击部门查询 账号
    searchDept(val) {
      this.depart_id = this.depart_id === val.udId ? null : val.udId
      // 触发查询
      this.search()
    },
    // 点击身份节点，查询数据
    searchRole(val) {
      console.log(val)
      this.searchForm.systemRoleId = val.roleId ? val.roleId : null
      // this.role_id = this.role_id === val.roleId ? val.roleId : null
      this.search()
    },
    changeStatus(val, item, index) {
      if (item.status === 0 && val === 1) {
        // 离职人员启用账号
        this.leaveVisible = true
        this.leaveForm.userId = item.userId
        this.leaveForm.state = val
        this.leaveForm.index = index
        userSystem(item.userId).then(res => {
          if (res.code === '000000' && res.data && res.data.usIds) {
            this.leaveSystemList = res.data.systemInfoVoList // 系统列表，
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        const str = val === 1 ? '确定启用该账号？' : '确定禁用该账号？'
        this.$confirm(str, '提示', {
          type: 'warning',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          const params = {
            userId: item.userId,
            state: val
          }
          updateUserState(params).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.message)
              this.getData()
            } else {
              this.$message.error(res.message)
              item.state = val === 1 ? 2 : 1
            }
          }).catch(() => {
            item.state = val === 1 ? 2 : 1
          })
        }).catch(() => {
          item.state = val === 1 ? 2 : 1
        })
      }
    },
    submitState() {
      this.$refs.leaveForm.validate((valid) => {
        if (valid) {
          const str = '确定启用该账号？'
          this.$confirm(str, '提示', {
            type: 'warning',
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            const params = {
              effectTime: this.leaveForm.effectTime,
              userId: this.leaveForm.userId,
              state: this.leaveForm.state,
              // usIds: this.leaveForm.usIds
            }
            updateUserState(params).then(res => {
              if (res.code === '000000') {
                this.$message.success(res.message)
                this.getData()
                this.leaveVisible = false
                this.leaveSystemList = []
                this.$refs.leaveForm.resetFields()
              } else {
                this.$message.error(res.message)
                this.closeLeaveDialog()
              }
            }).catch(() => {
              this.closeLeaveDialog()
            })
          }).catch((e) => {
            console.log(e)
            this.closeLeaveDialog()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    closeLeaveDialog() {
      this.leaveVisible = false
      this.dataList[this.leaveForm.index].state = 2
      this.leaveSystemList = []
      this.$refs.leaveForm.resetFields()
    },
    // 系统权限
    powerConf(item) {
      // console.log(item)
      userSystem(item.userId).then(res => {
        if (res.code === '000000' && res.data && res.data.usIds) {
          // this.editOptions.usIds = res.data.usIds
          // if (isOld) {
          //   this.powerObjOld = {
          //     visible: true,
          //     userId: item.userId,
          //     systemList: res.data.systemInfoVoList // 系统列表，
          //   }
          // } else {
          this.powerObj = {
            visible: true,
            userId: item.userId,
            systemList: res.data.systemInfoVoList // 系统列表，
          }
          // }
        } else {
          // this.editOptions.usIds = []
          this.$message.warning('该账号暂无系统可分配')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 账号编辑
    editAccount(item) {
      // usIds 需根据userId 获取，当前账号拥有哪些系统
      // console.log(item)
      const p1 = this.getRoles(item.userId)
      const p2 = this.getSystems(item.userId)
      Promise.all([p1, p2]).then(res => {
        Object.keys(this.editOptions).forEach(key => {
          // if (item[key]) {
          this.editOptions[key] = item[key]
          // }
        })
        this.editOptions.roleIds = res[0]
        this.editOptions.usIds = res[1]
        if (this.editOptions.systemRoleUserIdVoList && this.editOptions.systemRoleUserIdVoList.length > 0) {
          const systemRoleIds = this.editOptions.systemRoleUserIdVoList.map(item => item.roleId)
          this.editOptions.sysRoleIds = roleChosedReset(systemRoleIds, this.roleTree)
        } else {
          this.editOptions.sysRoleIds = []
        }
        this.editOptions.visible = true
        console.log(this.editOptions)
      }).catch(err => {
        console.log(err)
      })
    },
    closeEdit() {
      this.editOptions.visible = false
      this.editOptions.userId = null
    },
    closeAdd() {
      this.addVisible = false
    },
    closeProduct() {
      this.productlineObj = {
        visible: false,
        productLineList: [],
        checkedList: [],
        userId: null
      }
    },
    // 分配身份权限
    roleConf(item) {
      this.checkedIndetify = item.roleIdList
      this.identify = true
      // setTimeout(() => {
      //   this.$nextTick(() => {
      //     this.$refs.tree.setCheckedKeys(this.checkedIndetify)
      //   })
      // }, 100)
    },
    filterNode(value, data) {
      console.log(data)
      if (!value) return true
      return data.rolename.indexOf(value) !== -1
    },
    closeTab() {
      this.filterText = ''
      this.checkedIndetify = []
      this.identify = false
    },
    submit() {
      console.log('commit')
    },
    resetPwd() {
      this.$confirm('是否将该用户的密码重置为初始密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          userId: this.editOptions.userId
        }
        console.log('params', params)
        resetPassword(params)
          .then(response => {
            console.log('params', params)
          })
          .catch(error => {
            console.log(error)
          })
        this.$message({
          type: 'success',
          message: '重置密码成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置密码'
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.el-aside .el-tabs__content {
  min-height: 300px !important;
}

/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #c4defc;
}
</style>

