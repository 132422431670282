<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="编辑账号" width="800px"
      :before-close="closeTab" :close-on-click-modal="false">
      <el-form ref="formData" :model="formData" inline :rules="rules" size="small" label-width="100px">
        <el-form-item label="登录名" prop="username">
          <el-input v-model="formData.username" placeholder="登录账号" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="账号姓名" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="英文名" prop="nameEn">
          <el-input v-model="formData.nameEn" placeholder="英文名" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="座机" prop="telephone">
          <el-input v-model="formData.telephone" placeholder="座机号码" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobilePhone">
          <el-input v-model="formData.mobilePhone" placeholder="手机号码" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email" placeholder="邮箱" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="身份" prop="roleId">
          <el-select v-model="formData.roleId" placeholder="请选择" :style="inputWidth">
            <el-option label="超级管理员" :value="1"></el-option>
            <el-option label="公司管理员" :value="2"></el-option>
            <el-option label="普通用户" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司" prop="ucName">
          <el-input v-model="formData.ucName" disabled :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="udName">
          <el-input v-model="formData.udName" disabled :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="upName">
          <el-input v-model="formData.upName" disabled :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="拥有系统">
          <el-select v-model="formData.usIds" placeholder="选择系统" :collapse-tags="true" multiple closeable :style="inputWidth">
            <el-option v-for="item in systemList" :key="item.usId" :value="item.usId" :label="item.usName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品线角色">
          <el-select v-model="formData.roleIds" placeholder="选择产品线角色" :collapse-tags="true" multiple closeable :style="inputWidth">
            <el-option v-for="item in productLineRoleList" :key="item.roleId" :value="item.roleId" :label="item.roleName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拥有的角色">
          <el-cascader :options="roleTree" :props="defaultProps" v-model="formData.sysRoleIds" clearable placeholder="请勾选角色" :style="inputWidth"></el-cascader>
        </el-form-item>
        <!-- 编辑时 绑定的身份关系不可改 -->
        <!-- <el-form-item label="拥有身份"> -->
          <!-- <el-input v-model="formData.roleShow" :style="inputWidth" disabled :title="formData.roleShow"></el-input> -->
          <!-- <el-cascader
            v-model="formData.roleIds"
            :options="roleIds"
            :props="defaultProps"
            multiple
            :collapse-tags="true"
            change-on-select
            :show-all-levels="false"
            clearable
            :style="inputWidth"
            placeholder="请选择身份">
          </el-cascader> -->
        <!-- </el-form-item> -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input type="textarea" :rows="3" v-model="formData.description" :style="inputWidth"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否在公司以外的地点登录" label-width="200" style="margin-bottom: 5px;">
              <el-switch v-model="formData.isOutside"
                active-value="1" inactive-value="0"
                active-text="允许" inactive-text="不允许"
                active-color="#13ce66"
                inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <el-form-item label="">
              <el-button type="warning" size="mini" @click="resetPwd()">重置密码</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updateUser } from '@/api/account'
import * as validFn from '@/utils/validate'
export default {
  data() {
    return {
      formData: {
        userId: null,
        username: '',
        name: '',
        nameEn: '',
        telephone: '',
        mobilePhone: '',
        email: '',
        roleId: null,
        ucId: null,
        usIds: [],
        roleIds: [],
        // roleShow: '',
        departmentId: null,
        positionId: null,
        ucName: '',
        udName: '',
        upName: '',
        description: '',
        isOutside: 0,
        sysRoleIds: []
      },
      visible: false,
      rules: {
        username: [validFn.required()],
        mobilePhone: [validFn.required()],
        email: [validFn.required(), validFn.email()],
        roleId: [validFn.requiredC()],
        ucName: [validFn.required()],
        udName: [validFn.required()],
        upName: [validFn.required()]
      },
      defaultProps: {
        label: 'roleName',
        value: 'roleId',
        children: 'supRoleList',
        expandTrigger: 'hover',
        checkStrictly: true,
        multiple: true
      },
      inputWidth: 'width:240px',
      waiting: false
    }
  },
  props: {
    // visible: {
    //   type: Boolean,
    //   default: false
    // },
    // userId: {
    //   type: Number,
    //   default: null
    // },
    usIds: {
      type: Array,
      default: function() {
        return []
      }
    },
    editOptions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    systemList: {
      type: Array,
      default: function() {
        return []
      }
    },
    roleIds: {
      type: Array,
      default: function() {
        return []
      }
    },
    productLineRoleList: {
      type: Array,
      default: function() {
        return []
      }
    },
    roleTree: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  watch: {
    'editOptions.visible': function(val) {
      if (val) {
        if (this.editOptions.userId) {
          Object.keys(this.formData).forEach(key => {
            if (this.editOptions[key]) {
              this.formData[key] = this.editOptions[key]
            }
          })
          // this.formData.roleShow = this.formData.roleIds.join(',')
        }
        // this.formData.usIds = this.usIds
        this.visible = true
      } else {
        this.visible = false
      }
    }
  },
  mounted() {

  },
  methods: {
    closeTab() {
      this.$emit('close')
    },
    resetPwd() {
      this.$parent.resetPwd()
    },
    submit() {
      this.waiting = true
      console.log(this.formData)
      // 选择的角色 只保留对应的id（不传id的上级id）
      if (this.formData.sysRoleIds && this.formData.sysRoleIds.length > 0) {
        this.formData.sysRoleIds = this.formData.sysRoleIds.map(item => item[item.length - 1])
      }
      updateUser(this.formData).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.$emit('close')
          this.$emit('success')
        } else {
          this.$message.success(res.message)
        }
        this.waiting = false
      }).catch(err => {
        console.log(err)
        this.waiting = false
      })
    }
  }
}
</script>
