<template>
  <div v-if="visible">
    <el-dialog v-ismove :visible="true" title="新增账号" width="800px"
      :before-close="closeTab" :close-on-click-modal="false">
      <el-form ref="formData" :model="formData" inline :rules="rules" size="small" label-width="100px">
        <el-form-item label="登录名" prop="username">
          <el-input v-model="formData.username" placeholder="登录账号" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="账号姓名" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="英文名" prop="nameEn">
          <el-input v-model="formData.nameEn" placeholder="英文名" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="座机" prop="telephone">
          <el-input v-model="formData.telephone" placeholder="座机号码" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobilePhone">
          <el-input v-model="formData.mobilePhone" placeholder="手机号码" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="formData.email" placeholder="邮箱" :style="inputWidth"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="formData.password"
            show-password
            :style="inputWidth"
            autocomplete="on"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            placeholder="请再次输入密码"
            v-model="formData.checkPass"
            show-password
            :style="inputWidth"
            autocomplete="on"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份" prop="roleId">
          <el-select v-model="formData.roleId" placeholder="请选择" :style="inputWidth">
            <el-option label="超级管理员" :value="1"></el-option>
            <el-option label="公司管理员" :value="2"></el-option>
            <el-option label="普通用户" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="公司" prop="ucId">
          <el-cascader
            v-model="formData.ucId"
            :options="companyData"
            :props="companyProps"
            change-on-select
            @change="getChildCompany"
            :style="inputWidth"
            :show-all-levels="false">
          </el-cascader>
        </el-form-item> -->
        <el-form-item label="部门" prop="udId">
          <el-cascader
            v-model="formData.udId"
            :options="departTree"
            :props="departProps"
            change-on-select
            @change="getChildDepart"
            :style="inputWidth"
            clearable
            placeholder="请先选择公司">
          </el-cascader>
        </el-form-item>
        <el-form-item label="职位" prop="upId">
          <el-cascader
            v-model="formData.upId"
            :options="positionData"
            :props="positionProps"
            change-on-select
            @change="getPosition"
            :show-all-levels="false"
            :style="inputWidth"
            clearable
            placeholder="请先选择部门">
          </el-cascader>
        </el-form-item>
        <el-form-item label="拥有系统">
          <el-select v-model="formData.usIds" placeholder="选择系统" :collapse-tags="true" multiple closable :style="inputWidth">
            <el-option v-for="item in systemList" :key="item.usId" :value="item.usId" :label="item.usName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品线角色">
          <el-select v-model="formData.roleIds" placeholder="选择产品线角色" :collapse-tags="true" multiple closeable :style="inputWidth">
            <el-option v-for="item in productLineRoleList" :key="item.roleId" :value="item.roleId" :label="item.roleName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拥有的角色">
          <el-cascader :options="roleTree" :props="defaultProps" v-model="formData.sysRoleIds" clearable placeholder="请勾选角色" :style="inputWidth"></el-cascader>
        </el-form-item>
        <el-form-item label="是否在公司以外的地点登录登录" label-width="200" style="margin-bottom: 5px;">
          <el-switch v-model="formData.isOutside"
            active-value="1" inactive-value="0"
            active-text="允许" inactive-text="不允许"
            active-color="#13ce66"
            inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :rows="3" v-model="formData.description" :style="inputWidth"></el-input>
        </el-form-item>
        <!-- <el-form-item label="拥有身份">
          <el-cascader
            v-model="formData.roleIds"
            :options="roleList"
            :props="defaultProps"
            multiple
            :collapse-tags="true"
            change-on-select
            :show-all-levels="false"
            clearable
            :style="inputWidth"
            placeholder="请选择身份">
          </el-cascader>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="submit" :loading="waiting">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { deepClone } from '@/utils/index'
import { getuserPosition, addUserInfo } from '@/api/account'
export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码~~~'))
      } else {
        if (this.formData.checkPass !== '') {
          this.$refs.formData.validateField('checkPass')
        }
        callback()
      }
    }

    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码!!!'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePhoneNum = (rule, value, callback) => {
      const phoneReg = /^1[0-9][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      formData: {
        userId: null,
        username: '',
        name: '',
        nameEn: '',
        telephone: '',
        mobilePhone: '',
        email: '',
        password: '',
        checkPass: '',
        roleIds: [],
        roleId: null,
        // ucId: [],
        udId: [],
        upId: [],
        usIds: [],
        // roleIds: [], // 身份 要单独配置，身份影响绑定的系统及权限数据
        description: '',
        isOutside: 0,
        sysRoleIds: []
      },
      rules: {
        username: [validFn.required()],
        mobilePhone: [
          { required: true, validator: validatePhoneNum, trigger: 'blur' }
        ],
        email: [validFn.required(), validFn.email()],
        password: [
          { required: true, validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        roleId: [validFn.requiredC()],
        // ucId: [validFn.requiredC()],
        udId: [validFn.requiredC()],
        upId: [validFn.requiredC()]
      },
      inputWidth: 'width:240px',
      // companyData: [],
      departData: [],
      positionData: [],
      waiting: false,
      defaultProps: {
        label: 'roleName',
        value: 'roleId',
        children: 'supRoleList',
        expandTrigger: 'hover',
        checkStrictly: true,
        multiple: true
      },
      departProps: {
        label: 'udName',
        value: 'udId',
        children: 'children',
        expandTrigger: 'hover'
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    systemList: {
      type: Array,
      default: function() {
        return []
      }
    },
    // roleList: {
    //   type: Array,
    //   default: function() {
    //     return []
    //   }
    // },
    // companyList: {
    //   type: Array,
    //   default: function() {
    //     return []
    //   }
    // },
    departTree: {
      type: Array,
      default: function() {
        return []
      }
    },
    productLineRoleList: {
      type: Array,
      default: function() {
        return []
      }
    },
    positionProps: {
      type: Object,
      default: function() {
        return {}
      }
    },
    roleTree: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        // 查询部门
        // this.companyData = deepClone(this.companyList)
      }
    }
  },
  mounted() {

  },
  methods: {
    // 部门级联，每次用部门ID去请求子部门ID列表
    getChildDepart(val) {
      console.log(val)
      const ID = this.formData.udId[this.formData.udId.length - 1]
      // 获取职位列表(级联数据)
      getuserPosition(ID).then(res => {
        if (res.code === '000000') {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenPositionList = []
            }
          })
          this.positionData = res.data
        }
      }).catch(() => {
        this.positionData = []
      })
      this.$forceUpdate()
    },
    // 职位级联，每次用职位ID去请求下级职位列表
    getPosition() {
      const ID = this.formData.upId[this.formData.upId.length - 1]
      const udID = this.formData.udId[this.formData.udId.length - 1]
      getuserPosition(udID, ID).then(res => {
        if (res.code === '000000') {
          res.data.forEach(item => {
            if (item.hasMore) {
              item.childrenPositionList = []
            }
          })
          const parent = deepClone(this.positionData)
          this.findParent(ID, parent, res.data, 'childrenPositionList', 'upId')
          setTimeout(() => {
            this.positionData = parent
          }, 100)
        }
      }).catch(() => {
        this.positionData = []
      })
      this.$forceUpdate()
    },
    // 递归查找子公司列表该插到那个id下(部门，职位公用)
    findParent(id, arr, child, type, idType) {
      arr.forEach(item => {
        if (id === item[idType]) {
          if (child.length > 0) {
            item[type] = child
          }
        } else {
          if (item[type]) {
            this.findParent(id, item[type], child, type, idType)
          }
        }
      })
    },
    closeTab() {
      this.$emit('close')
    },
    submit() {
      this.waiting = true
      console.log(this.formData)
      const params = {
        username: this.formData.username,
        departmentId: this.formData.udId[this.formData.udId.length - 1],
        description: this.formData.description,
        email: this.formData.email,
        isOutside: this.formData.isOutside,
        mobilePhone: this.formData.mobilePhone,
        name: this.formData.name,
        nameEn: this.formData.nameEn,
        password: this.formData.password,
        positionId: this.formData.upId[this.formData.upId.length - 1],
        roleId: this.formData.roleId,
        roleIds: this.formData.roleIds,
        telephone: this.formData.telephone,
        ucId: process.env.VUE_APP_COMPANY_ID, // 后续后端删除该字段
        usIds: this.formData.usIds,
        sysRoleIds: this.formData.sysRoleIds.length > 0 ? this.formData.sysRoleIds.map(item => item[item.length - 1]) : 0
      }
      addUserInfo(params).then(res => {
        if (res.code === '000000') {
          this.$message.success(res.message)
          this.$emit('close')
          this.$emit('success')
        } else {
          this.$message.error(res.message)
        }
        this.waiting = false
      }).catch(err => {
        console.log(err)
        this.waiting = false
      })
    }
  }
}
</script>
